<template>
  <el-dialog :title="title"
             :visible.sync="outerVisible"
             @close="close"
             width="100%">

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="파트너정보1" name="partnerInfo1">
        <table class="table100">
          <tr>
            <th style="width: 14%">아이디</th>
            <th style="width: 14%">닉네임</th>
            <th style="width: 14%">비밀번호</th>
            <th style="width: 14%">은행명</th>
            <th style="width: 14%">계좌번호</th>
            <th style="width: 14%">예금주</th>
            <th style="width: 14%"></th>
          </tr>
          <tr>
            <td>
              <span v-if="mode == managerConst.MODE_EDIT">{{ agent.account }}</span>
              <el-input v-if="mode == managerConst.MODE_SAVE" size="mini"
                        v-model="agent.account"></el-input>
            </td>
            <td>
              <el-input size="mini"
                        v-model="agent.nickname"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.passwd"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.bank" v-if="mode == managerConst.MODE_EDIT"></el-input>
              <el-select size="mini" v-model="agent.bank" v-if="mode == managerConst.MODE_SAVE">
                <el-option value="">- - - 은행선택 - - -</el-option>
                <el-option value="카카오뱅크">카카오뱅크</el-option>
                <el-option value="케이뱅크">케이뱅크</el-option>
                <el-option value="신한">신한은행</el-option>
                <el-option value="국민">국민은행</el-option>
                <el-option value="농협">농협</el-option>
                <el-option value="우리">우리은행</el-option>
                <el-option value="하나">하나은행</el-option>
                <el-option value="기업">기업은행</el-option>
                <el-option value="우체국">우체국</el-option>
                <el-option value="대구">대구은행</el-option>
                <el-option value="경남">경남은행</el-option>
                <el-option value="광주">광주은행</el-option>
                <el-option value="부산">부산은행</el-option>
                <el-option value="신협">신협은행</el-option>
                <el-option value="전북">전북은행</el-option>
                <el-option value="제주">제주은행</el-option>
                <el-option value="씨티">씨티은행</el-option>
                <el-option value="새마을">새마을금고</el-option>
                <el-option value="SC제일은행">SC제일은행</el-option>
              </el-select>
            </td>
            <td>
              <el-input size="mini" v-model="agent.acNo"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.beneficiary"></el-input>
            </td>
            <td></td>

          </tr>
          <tr>
            <th style="width: 14%">환전비번</th>
            <th style="width: 14%">가입레벨</th>
            <th style="width: 14%">가입그룹</th>
            <th style="width: 14%">상태</th>
            <th style="width: 14%">회원가입</th>
            <th style="width: 14%">머니이동</th>
            <th style="width: 14%">출금가능여부</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="agent.extractPasswd"></el-input>
            </td>
            <td>
              <!--레벨-->
              <el-select size="mini" v-model="agent.defaultUserRank" placeholder="레벨 선택"
                         style="width: 80%">
                <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">
                  {{ rank.rankName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="agent.defaultUserGroup" placeholder="그룹 선택"
                         style="width: 80%">
                <el-option v-for="group in groupList" :value="group.id" :label="group.groupName">
                  {{ group.groupName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="agent.status" placeholder="상태" style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="블럭">블럭</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="agent.registable" placeholder="회원가입" style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="agent.moneymoveable" placeholder="머니이동"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="agent.exchangeable" placeholder="출금가능여부"
                         style="width: 80%">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th style="width: 14%">손익금%</th>
            <th style="width: 14%">(스포츠)베팅%</th>
            <th style="width: 14%">(미니게임)베팅%</th>
            <th style="width: 14%" colspan="2">(카지노)베팅%</th>
            <th style="width: 14%" colspan="2">(슬롯)베팅%</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="agent.rechargeExchangeProfit"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.sportsBetRollingProfit"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.leisureBetRollingProfit"></el-input>
            </td>
            <td colspan="2">
              <el-input size="mini" v-model="agent.casinoBetRollingProfit"></el-input>
            </td>
            <td colspan="2">
              <el-input size="mini" v-model="agent.slotBetRollingProfit"></el-input>
            </td>
          </tr>
          <tr v-if="mode == managerConst.MODE_EDIT">
            <th>보유머니</th>
            <th>카지노공박기개수</th>
            <th>슬롯공박기개수</th>
            <th colspan="6"></th>
          </tr>
          <tr v-if="mode == managerConst.MODE_EDIT">
            <td>{{ agent.cash|comma }}
              <el-select size="mini" v-model="agent.flowType" placeholder="추가 or 차감"
                         style="width: 100px;margin-left: 3px;">
                <el-option value="1" label="추가">추가(+)</el-option>
                <el-option value="2" label="차감">차감(-)</el-option>
              </el-select>
              <el-input size="mini" type="number" placeholder="수량" v-model="agent.flowCash"
                        style="width: 200px;margin-left: 3px;"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.casinopasscount"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="agent.slotpasscount"></el-input>
            </td>
            <td colspan="6" style="text-align: left;padding-left: 10px">

            </td>
          </tr>

        </table>
      </el-tab-pane>

      <el-tab-pane label="파트너정보2" v-if="mode === managerConst.MODE_EDIT" name="partnerInfo2">
        <h5>메모</h5>
        <el-input v-if="null != agent" type="textarea" v-model="agent.memo" rows="10"></el-input>
      </el-tab-pane>


      <el-tab-pane label="최근입금내역" v-if="mode === managerConst.MODE_EDIT" name="applyHistory2">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총입금: {{ exchangeAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
        </div>
        <el-table
            :data="exchangeList"
            style="width: 100%;margin-top: 10px"
            max-height="330"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <!--금액-->
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>
          <el-table-column
              label="날짜"
              width="150">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('yyyy-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <!--날짜-->
          <el-table-column
              label="상태"
              width="150">
            <template slot-scope="scope">
                                 <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_WAITING"
                                       class="badge badge-primary">미확인</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CHECKING"
                    class="badge badge-primary">처리중</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_COMPLITE"
                    class="text-blue">완료</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CANCEL"
                    class="text-red">취소</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20 ,50]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>


      <el-tab-pane label="최근출금내역" v-if="mode === managerConst.MODE_EDIT" name="applyHistory">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총출금: {{ exchangeAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
        </div>
        <el-table
            :data="exchangeList"
            style="width: 100%;margin-top: 10px"
            max-height="730"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <!--금액-->
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>
          <!--날짜-->
          <el-table-column
              label="날짜"
              width="150">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('yyyy-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column
              label="상태"
              width="150">
            <template slot-scope="scope">
                                 <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_WAITING"
                                       class="badge badge-primary">미확인</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CHECKING"
                    class="badge badge-primary">처리중</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_COMPLITE"
                    class="text-blue">완료</span>
              <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CANCEL"
                    class="text-red">취소</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20 ,50]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="머니내역" v-if="mode === managerConst.MODE_EDIT" name="moneyHistory">
        <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
          총금액: {{ parLogCashAmountTotal|comma }}
          <div class="block" style="margin-left: 10px">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="startDate"
                            align="right"
                            type="date"
                            placeholder="시작일 선택"
                            :picker-options="pickerOptions"
                            @change="setStartDate">
            </el-date-picker>
          </div>
          <div style="padding-left: 2px;padding-right: 2px">~</div>
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker size="mini"
                            v-model="endDate"
                            align="right"
                            type="date"
                            placeholder="마감일 선택"
                            :picker-options="pickerOptions"
                            @change="setEndDate">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
          <el-select v-model="partnerCashLog.display" @change="getPartnerLogCashHistory" size="mini" placeholder="상태"
                     style="width:110px;margin-left: 5px">
            <el-option label="전체" :value="null">전체</el-option>
            <el-option label="정상내역" :value="managerConst.ENABLE">정상내역</el-option>
            <el-option label="숨김내역" :value="managerConst.DISABLE">숨김내역</el-option>
          </el-select>

          <el-select size="mini" v-model="partnerCashLog.type" placeholder="머니내역선택"
                     style="width: 240px;margin-left: 3px;" @change="getPartnerLogCashHistory">
            <el-option :value="null" label="전체내역">전체내역</el-option>
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_MANAGER" label="수동내역">수동내역</el-option>
            <!--                        <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_POINT_BET_CASINO_AGENT_2_USER" label="총판->유저롤링(카지노베팅)">총판->유저롤링(카지노베팅)</el-option>-->
            <!--                        <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_POINT_BET_LEISURE_AGENT_2_USER" label="총판->유저롤링(미니게임베팅)">총판->유저롤링(미니게임베팅)</el-option>-->
            <el-option :value="managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_DAILI" label="부본사->총판 머니이동">
              부본사->총판 머니이동
            </el-option>
          </el-select>
        </div>
        <el-table
            :data="partnerLogCashList"
            style="width: 100%;margin-top: 10px"
            max-height="730"
            border>
          <!--번호-->
          <el-table-column
              label="번호"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column
              label="내용"
              width="900">
            <template slot-scope="scope">
              {{ scope.row.content }}
            </template>
          </el-table-column>
          <el-table-column
              label="전 보유금"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.oldValue|comma }}
            </template>
          </el-table-column>
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
                            <span class="text-blue"
                                  v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_PLUS">+{{
                                scope.row.amount|comma
                              }}</span>
              <span class="text-danger"
                    v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_MINUS">{{
                  (scope.row.amount * -1)|comma
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="후 보유금"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.currentValue|comma }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagePanel">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[20 ,50 ,100]"
              :page-size="pageSize"
              layout="total,  prev, pager, next"
              :total="pageTotal">
          </el-pagination>
        </div>
      </el-tab-pane>

    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <el-button @click="save()">저장하기</el-button>
      <el-button @click="outerVisible = false">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
import managerConst from "../../../common/administrator/managerConst";
import {getRankList} from "../../../network/manager/rankRequest";
import {getGroupList} from "../../../network/manager/groupRequest";

import {getApplyExchangeForPartnerList} from "../../../network/manager/applyRequest";
import {getLogCashPointList} from "../../../network/manager/logCashPointRequest";
import {getAgentById, saveAgent, updateAgent} from "../../../network/manager/agentRequest";

export default {
  name: "AgentEditToast",
  components: {},
  data() {
    return {
      title: '',
      agentId: null, //
      agent: {},
      closed: false,
      outerVisible: false,
      managerConst: managerConst,
      mode: managerConst.MODE_SAVE, //save:등록 ,edit:수정,
      partnerList: [],
      rankList: [],
      groupList: [],
      activeName: 'partnerInfo1',
      pageNum: 1,
      pageSize: 20,
      pageTotal: 0,
      startDate: '',
      endDate: '',
      exchangeList: [],
      exchangeAmountTotal: 0,
      exchangeCountTotal: 0,
      currentTabName: null,
      partnerCashLog: {type: null, display: managerConst.YES},
      parLogCashAmountTotal: 0,
      partnerLogCashList: [],
      pickerOptions: {
        shortcuts: [{
          text: '오늘',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '어제',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '일주일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '15일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
            picker.$emit('pick', date);
          }
        }, {
          text: '30일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {
    search() {
      if (this.currentTabName == 'applyHistory') {
        this.getPartnerApplyHistory()
      }
      if (this.currentTabName == 'applyHistory2') {
        this.getPartnerApplyHistory2()
      }
      if (this.currentTabName == 'moneyHistory') {
        this.getPartnerLogCashHistory()
      }
    },
    close() {
      this.agent = {}
      this.agentId = null
      this.closed = true
      this.outerVisible = false
    },
    destroyElement() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    save() {
      if (this.mode === managerConst.MODE_SAVE) {
        this.agent.parentId = this.agentId
        saveAgent(this.agent).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '파트너 등록이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('agentEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
      if (this.mode === managerConst.MODE_EDIT) {
        updateAgent(this.agent).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '에이전트 정보 수정이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('agentEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
    },
    handleClick(tab, event) {
      this.startDate = this.$moment().startOf('month').format('yyyy-MM-DD')
      this.endDate = this.$moment().endOf('month').format('yyyy-MM-DD')
      this.exchangeList = []
      this.partnerLogCashList = []
      this.pageNum = 1
      this.pageTotal = 0
      if (tab.name == 'applyHistory') {
        this.currentTabName = 'applyHistory'
        this.getPartnerApplyHistory()
      }
      if (tab.name == 'applyHistory2') {
        this.currentTabName = 'applyHistory2'
        this.getPartnerApplyHistory2()
      }
      if (tab.name == 'moneyHistory') {
        this.currentTabName = 'moneyHistory'
        this.getPartnerLogCashHistory();
      }
    },
    getAgentById() {
      getAgentById(this.agentId).then(res => {
        this.agent = res.data.data
        this.title = '에이전트 [' + this.agent.nickname + '] 님 상세정보'
      })
    },

    setStartDate() {
      this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
    },
    setEndDate() {
      this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
    },
    getPartnerApplyHistory() {
      let record = {}
      record.partnerId = this.agentId
      record.startDate = this.startDate
      record.endDate = this.endDate
      record.status = managerConst.ApplyCash.STATUS_COMPLITE
      record.applyType = managerConst.ApplyCash.ATYPE_EXCHANGE
      getApplyExchangeForPartnerList(record, this.pageNum, this.pageSize).then(res => {
        this.exchangeList = res.data.data.cashList
        this.exchangeAmountTotal = res.data.data.totalCash;
        this.exchangeCountTotal = res.data.data.totalCount;
        this.pageTotal = res.data.data.totalCount
      })
    },
    getPartnerApplyHistory2() {
      let record = {}
      record.partnerId = this.agentId
      record.startDate = this.startDate
      record.endDate = this.endDate
      record.status = managerConst.ApplyCash.STATUS_COMPLITE
      record.applyType = managerConst.ApplyCash.ATYPE_RECHARGE
      getApplyExchangeForPartnerList(record, this.pageNum, this.pageSize).then(res => {
        this.exchangeList = res.data.data.cashList
        this.exchangeAmountTotal = res.data.data.totalCash;
        this.exchangeCountTotal = res.data.data.totalCount;
        this.pageTotal = res.data.data.totalCount
      })
    },
    getPartnerLogCashHistory() {
      let record = {}
      this.partnerCashLog.partnerId = this.agentId
      this.partnerCashLog.startDate = this.startDate
      this.partnerCashLog.endDate = this.endDate
      // record.cashOrPoint = managerConst.CashPointLog.LOG_CASHPOINT_CASH
      this.partnerCashLog.userOrPartner = managerConst.CashPointLog.LOG_CASHPOINT_PARTNER
      getLogCashPointList(this.partnerCashLog, this.pageNum, this.pageSize).then(res => {
        this.partnerLogCashList = res.data.data.result
        this.parLogCashAmountTotal = res.data.data.totalAmount
        this.pageTotal = res.data.data.pageTotal
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.search();
    },
  },
  created() {
    this.outerVisible = true
    getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.rankList = res.data.data
    })
    getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.groupList = res.data.data
    })
    if (this.mode === managerConst.MODE_EDIT) {
      this.getAgentById()
    } else {
      this.agent.status = managerConst.ENABLE;
      this.agent.registable = managerConst.ENABLE;
      this.agent.moneymoveable = managerConst.DISABLE;
      let parentId = this.agentId == managerConst.Parnter.PATNER_GENERALAGENT_NOID ? managerConst.Parnter.PATNER_GENERALAGENT_NOID : this.agentId;
      this.agent.parentId = parentId
    }
  },
  mounted() {

  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.outerVisible = false
        this.destroyElement()
      }
    }
  },

}
</script>

<style scoped>
.table100 td {
  padding: 5px;
  background-color: #cfcfd1 !important;
}

.table100 th {
  width: 20%;
}

.table100 .el-input__inner {
  width: 80% !important;
}

.table_userstatisc {
  width: 100%;
  margin-bottom: 5px;
}

.table_userstatisc th {
  width: 14%;
}

.table_userstatisc td {
  background-color: #e8e8e8 !important;
  width: 14%;
}

</style>